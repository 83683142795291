$primary: #83b333; /* MAIN COLOR */
$secondary: #f173ac; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

 @import 'https://fonts.googleapis.com/css?family=Coustard|Crafty+Girls|Fredericka+the+Great|Walter+Turncoat';

body {
	font-family: 'Josefin Sans', sans-serif;
}

nav {
	z-index: 1000;
}
.navbar-default {
	background: darken($primary, 20%);
	border: none;
	border-radius: 0;
	padding: 1em 0em;
	color: $wht;

}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 80px;
		display: table-cell;
		vertical-align: middle;
	    color: $wht;
		
	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	width: 350px;
	text-align: center;

	#myModalLabel,label {font-family: "Josefin Sans", sans-serif;}
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
		color: $blk;

		&:focus {
			border-bottom: 1px solid darken($primary,20%);
			box-shadow: 0 2px 0 0 $primary;
		}
	}
	
	button {
		display: block;
		width: 25%;
		margin: 0 auto;
		font-size: 1.1em;
		border: none;

		&:hover {
			background: transparent;
			box-shadow: none;
			color: $wht;
			text-shadow: 0px 0px 5px $blk;
		}
	}
	
	input#submit {
		background: $primary;
		font-size: 	1.25em;
		margin: 0 auto;

		&:hover {
			background: lighten($primary,10%);

		}
	}

	input[type="submit"] {
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: darken($primary, 20%);
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}


h2 {
	font: normal 300 40px/1 'Josefin Sans', sans-serif;
	line-height: 1.25em;
	font-weight: bold;
}

h3 {
	font-family:'Josefin Sans', sans-serif;
	font-size: 	2.25em;
	line-height: 	1.5em;	
}

.privacy,.terms {
	h1,h2,h3
	{
		color: 	$blk;
	}
}

.hrPrimary {
	border: 2px solid $primary;
}

.marBtm {margin-bottom: 3em;}
.marTop { margin-top: 3em;}


@mixin btn {
	font-size: 	2em;
	padding: 1em 2em;
	background: $primary;
	color: 	$wht;
	border-radius: 10px;
	transition-duration: 0.5s;

	&:hover {
		background: $wht;
		color: 	$primary;
		border-radius: 	30px;
		box-shadow: 0px 0px 15px rgba($blk, 0.8);
	}
}

.btn {
	@include btn;
}

.row {
	margin: 0;
}

.blkTxt {
	color: $blk !important;
}










/** SCROLL STYLES **/

h1,h2,h3,h4 { 
	color: $wht; 
	text-shadow: 0px 0px 10px $primary;
	font-family: 'Crafty Girls',sans-serif;
}

hr { 
	border: 1px solid $secondary;
	box-shadow: 0px 0px 10px $primary; 
}

h1 {
	font-size: 4em;
}

p {
	color: $wht;
	font-family: 'Walter Turncoat',sans-serif;
	font-size: 2em;
}

.privacy p, .terms p {
	color: $blk;
}

#sticky-wrapper {
	margin-bottom: -119px;
}

section {
	position: relative;
	// width: 100vw;
	height: 100vh;
	margin-top: 0em;
	padding: 5em 0em 10em;

	h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		color: #fff;
		font-family: 'Crafty Girls',sans-serif;
		text-align: center;

		@media (max-width: 768px) {
			font-size: 2em;
		}
	}

	a {
		padding-top: 60px;
	}

	a span {
		position: absolute;
		top: 0;
		left: 50%;
		width: 46px;
		height: 46px;
		margin-left: -23px;
		border: 1px solid $secondary;
		border-radius: 100%;
		box-sizing: border-box;

		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			content: '';
			width: 16px;
			height: 16px;
			margin: -12px 0 0 -8px;
			border-left: 1px solid $secondary;
			border-bottom: 1px solid $secondary;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
			box-sizing: border-box;
		}

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			content: '';
			width: 44px;
			height: 44px;
			box-shadow: 0 0 0 0 rgba(255,255,255,.1);
			border-radius: 100%;
			opacity: 0;
			-webkit-animation: sdb03 3s infinite;
			animation: sdb03 3s infinite;
			box-sizing: border-box;
		}
	}

}
		



#section1 { background: url(../img/img1.jpg) center center/cover no-repeat;}
#section2 { background: url(../img/img2.jpg) center center/cover no-repeat;}
#section3 { background: url(../img/img3.jpg) center center/cover no-repeat;}
#section4 { background: url(https://unsplash.it/1000/1000?image=830) center center / cover no-repeat;}
#section5 { background: url(https://unsplash.it/1000/1000?image=780) center center / cover no-repeat;}
#section6 { background: url(https://unsplash.it/1000/1000?image=777) center center / cover no-repeat;}
#section7 { background: url(https://unsplash.it/1000/1000?image=662) center center / cover no-repeat;}
#section8 { background: url(https://unsplash.it/1000/1000?image=702) center center / cover no-repeat;}
#section9 { background: url(https://unsplash.it/1000/1000?image=680) center center / cover no-repeat;}
#section10 { background: url(https://unsplash.it/1000/1000?image=723) center center / cover no-repeat;}


.demo a {
	position: absolute;
	bottom: 20px;
	left: 50%;
	z-index: 2;
	display: inline-block;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	color: $secondary;
	font-family: 'Walter Turncoat',sans-serif;
	font-size: 1.5em;
	font-style: italic;
	letter-spacing: .1em;
	text-decoration: none;
	transition: opacity .3s;
	opacity: 0.75;
}
.demo a:hover {
	opacity: 1;
}

#section3 {
	height: auto;
	padding-bottom: 3em;
}

@-webkit-keyframes sdb03 {
	0% {
		opacity: 0;
	}
	30% {
		opacity: 1;
	}
	60% {
		// box-shadow: 0 0 0 60px rgba(255,255,255,.1);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
@keyframes sdb03 {
	0% {
		opacity: 0;
	}
	30% {
		opacity: 1;
	}
	60% {
		// box-shadow: 0 0 0 60px rgba(255,255,255,.1);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}


.logo {
	@media (max-width: 	400px) {
		width: 	60%;
	}
}



/** END SCROLL STYLES **/


.top-pad {
	padding-top: 150px;
}

@media (max-width: 768px) {

}

@media (max-width: 991px) {
  .navbar-header {
    width: 30%;
  }

  .logo {
    max-width: 100%;
  }

  	h2 {
		font-size: 2em;
	}
}


@media (max-width: 767px) {

  .navbar .navbar-nav > li > a {
    height: 30px;
    display: inline-block;
  }
	
	.nav-divider {
		display: none !important;
	}

  .navbar-header {
    width: 100%;
  }

  .logo {
    max-width: 25%;
    margin-left: 1em;
	margin-top: 1em;
  }
}

footer p {
	font-size: 1.5em;
}